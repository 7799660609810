import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {MposDate} from 'app/blocks/util/mpos-date';
export interface ITarget extends IBaseEntity {
    id?: number;
    targetType?: string;
    startDate?: any;
    endDate?: any;
    targetValue?: number;
    achievedValue?: number;
}

export class Target extends BaseEntity implements ITarget {
    public id?: number;
    public targetType?: string;
    public startDate?: any;
    public endDate?: any;
    public targetValue?: number;
    public achievedValue?: number;

    constructor(target?) {
        super();
        if (target) {
            this.id = target.id;
            this.targetType = target.targetType;
            this.startDate = MposDate.newInstance(target.startDate);
            this.endDate = MposDate.newInstance(target.endDate);
            this.targetValue = target.targetValue;
            this.achievedValue = target.achievedValue;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): Target {
        return new Target(this);
    }
}
